<template>
  <div>
    <div class="current-header">
      <div style="display: grid; grid-template-columns: 6fr 1fr">
        <div class="title-step-header">
          Sélectionnez la colonne correspondante à l’information suivante :
        </div>
        <div class="step-header">{{ currentHeadersToMap.position }}/{{ headersToMap.length }}</div>
      </div>
      <div style="display: flex; align-items: center">
        <div class="header-title">{{ currentHeadersToMap.name }}</div>
        <div v-if="currentHeadersToMap.description" class="header-description-block">
          <uds-icon icon-name="info" class="icon" :color="udsColors.udsNeutral800" size="small" />
          <div class="header-description">{{ currentHeadersToMap.description }}</div>
        </div>
      </div>
    </div>
    <div class="headers-to-map-wrapper">
      <div v-if="!isHeaderMandatory" class="non-mapping-choice">
        <uds-radio-square
          :item-text="nonMappingHeaderChoice.name"
          :item="nonMappingHeaderChoice"
          :selected="isHeaderSelected(nonMappingHeaderChoice)"
          item-icon="target"
          @click="selectMapping(nonMappingHeaderChoice)"
        />
      </div>
      <div class="headers-to-map-title">
        {{ headersFile.length }} colonnes détectées dans votre fichier
      </div>
      <div class="headers-to-map-block">
        <div v-for="item in headersFile" :key="item.id">
          <uds-radio-square
            :item-text="item.name"
            :item="item"
            :selected="isHeaderSelected(item)"
            item-icon="target"
            @click="selectMapping(item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type HeadersToMap = {
  id: number;
  name: string;
  description?: string;
  completed: boolean;
  ongoing: boolean;
  required: boolean;
  position: number;
  mappingHeader: string | null;
};

type HeaderFile = {
  name: string;
  id: number;
  selected: boolean;
};

const emit = defineEmits<{
  (e: "selectMapping", mappging: { mappgingFile: HeaderFile }): void;
}>();

const props = defineProps<{
  headers: string[];
  headersFile: HeaderFile[];
  headersToMap: HeadersToMap[];
}>();

const udsColors = getUdsColors();

const nonMappingHeaderChoice: HeaderFile = {
  name: "Non concerné",
  id: -1,
  selected: false,
};

const currentHeadersToMap = computed(
  () => props.headersToMap.find((htm) => htm.ongoing) ?? props.headersToMap[0],
);

const isHeaderMandatory = computed(() => currentHeadersToMap.value.required);

function selectMapping(mappgingFile: HeaderFile): void {
  emit("selectMapping", { mappgingFile });
}

function isHeaderSelected(item: HeaderFile): boolean {
  return currentHeadersToMap.value.mappingHeader === item.name;
}
</script>

<style lang="scss" scoped>
.current-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $uds-spacing-2 $uds-spacing-3;
  gap: $uds-spacing-2;
  border: 1px solid $uds-neutral-400;
  border-radius: $uds-radius-1;
  font-size: $uds-spacing-2;
  margin-bottom: $uds-spacing-2;

  .title-step-header {
    font-weight: $uds-weight-450;
    color: $uds-neutral-900;
  }
  .step-header {
    justify-self: right;
    font-weight: $uds-weight-700;
    color: $uds-primary-500;
  }

  .header-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: $uds-spacing-1_5 $uds-spacing-3;
    border: 1px solid $uds-primary-300;
    border-radius: $uds-radius-1;
    background: $uds-white;
    color: $uds-neutral-900;
    font-weight: $uds-weight-450;
    margin-right: $uds-spacing-2;
    flex: 1 0 auto;
  }

  .header-description-block {
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: $uds-spacing-2;
  }

  .header-description {
    color: $uds-neutral-800;
    font-weight: $uds-weight-450;
  }
}

.non-mapping-choice {
  margin-bottom: $uds-spacing-3;
}

.headers-to-map-title {
  font-size: 14px;
  color: $uds-neutral-800;
  font-weight: $uds-weight-450;
  margin-bottom: $uds-spacing-2;
}
.headers-to-map-block {
  display: flex;
  gap: $uds-spacing-2;
  flex-wrap: wrap;
}

:deep(.ds-radio-input-container) {
  width: fit-content;
  height: fit-content;
  padding: $uds-spacing-2 $uds-spacing-3;
}
</style>

<template>
  <div class="upload-file-wrapper">
    <!-- InputUpload
      :options="{
        maxFiles: 1,
        maxFilesize: 1.9,
      }"
      subtitle="Cliquez ou glissez votre fichier ici. "
      :label="title"
      name="gescom_upload"
      append-icon="attach_file"
      :data="uploadedFiles"
      accepted-files=".csv, .txt"
      displayed-accepted-files="csv, txt · 2 Mo max."
      :external-connector-files="externalConnectorFiles"
    / -->

    <CommonsDropzoneContainer
      :extensions="['text/csv', 'text/plain']"
      :max-files="1"
      :max-size="1.9"
      :custom-text="true"
      :show-drop-box-button="true"
      @updated="addFile($event)"
    >
      <template #content>
        <div class="uds-headers --h4">
          <div>
            {{ title }}
          </div>
        </div>
        <div class="upload-file-subtitle">
          <div>Cliquez ou glissez vos fichier ici.</div>
          <div>(csv, txt · 2 Mo max.)</div>
        </div>
      </template>
    </CommonsDropzoneContainer>
  </div>
</template>
<script setup lang="ts">
const emit = defineEmits(["handleFile"]);

const props = defineProps<{
  type: number;
  title: string;
}>();

function addFile(files: File[]) {
  emit("handleFile", { files, type: props.type });
}
</script>

<style lang="scss" scoped>
.upload-file-wrapper {
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 191px;
  margin-top: $uds-spacing-1;
  border: none;
  border-radius: unset;
}

.upload-file-subtitle {
  color: $uds-neutral-800;
  font-weight: $uds-weight-450;
}
</style>

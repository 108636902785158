<template>
  <CommonsModalsModalBody height="auto" width="960px" :svg="svg">
    <template #title> Importer des données externes </template>

    <template #help>
      <div>
        La TVA à 20% s'applique pour la majorité des prestations de services.<br ><br >
        La TVA à 10% peut s'appliquer aux produits énergétiques comme le bois.<br ><br >
        La TVA à 5,5% peut s'appliquer aux produits essentiels alimentaires, d'hygiène.<br ><br >
        La TVA 2,1% peut s'appliquer sur les biens livrés en Corse.<br ><br >
        Le taux à 0% existe pour les vaccins et tests de dépistage.
      </div>
    </template>

    <template #content>
      <KeepAlive>
        <div class="sales-mapping-import-wrapper">
          <div v-show="currentStepId !== 3" class="step-title">
            Étape {{ currentStepId }}/2 : {{ currentStep.description }}
          </div>
          <template v-if="currentStepId === 1">
            <div :class="{ hidden: currentStepId !== 1 }">
              <div class="upload-description">
                Importez 1, 2 ou 3 fichiers CSV selon vos différents types de ventes.
              </div>
              <div class="upload-wrapper">
                <div v-for="file in files" :key="file.id">
                  <CommonsModalsSalesMappingImportUpload
                    :key="file.id"
                    :type="file.type"
                    :title="file.name"
                    @handle-file="handleFile($event)"
                  />
                </div>
              </div>
              <div v-if="hasError" class="message-error">
                <uds-icon
                  icon-name="info"
                  class="icon"
                  :color="udsColors.udsError500"
                  size="small"
                />
                Il semblerait qu'il ya ait des différences dans le nom des colonnes de vos fichiers.
                Veuillez vous assurer que tout vos fichiers ont bien la même configuration.
              </div>
              <div class="message-info">
                <uds-icon
                  icon-name="info"
                  class="icon"
                  :color="udsColors.udsNeutral800"
                  size="small"
                />
                <div class="message-content">
                  <div class="message-title">
                    Suivez ces indications pour garantir le bon import de vos données :
                  </div>
                  <div class="message-list">
                    <ol>
                      <li>Assurez-vous que les fichiers ont des noms de colonnes identiques</li>
                      <li>Évitez les virgules dans les noms des colonnes</li>
                      <li>Classez les ventes par taux de TVA</li>
                      <li>Utilisez le format de date JJ/MM/AAAA</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="currentStepId === 2">
            <CommonsModalsSalesMappingImportHeaders
              :headers="headers"
              :headers-file="headersFile"
              :headers-to-map="headersToMap"
              @select-mapping="selectMapping($event)"
            />
          </template>
          <template v-if="currentStepId === 3">
            <CommonsModalsSalesMappingImportTransactions :transactions="transactions" />
          </template>
        </div>
      </KeepAlive>
    </template>
    <template #footer>
      <uds-main-button v-if="currentStepId === 1" type="tertiary" @click="$emit('close')"
        >Annuler</uds-main-button
      >
      <uds-main-button v-else type="secondary" @click="handleStep('back')"
        >Précédent</uds-main-button
      >
      <uds-main-button
        class="next-button"
        :disabled="!isValid"
        :loading="isLoading"
        :icon-name="nextButtonConfig.needIcon ? 'arrow_right_alt' : null"
        :icon-right="nextButtonConfig.needIcon"
        @click="handleStep"
        >{{ nextButtonConfig.name }}</uds-main-button
      >
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type { HeadersMappingImport, ImportRow } from "@silexpert/core";
import { ProductTypeId, commercialImportHeader } from "@silexpert/core";

type FileMappingType = {
  id: number;
  files: File[];
  type: ProductTypeId;
  name: string;
};

type HeadersToMap = {
  id: number;
  name: string;
  description?: string;
  completed: boolean;
  ongoing: boolean;
  required: boolean;
  position: number;
  mappingHeader: string | null;
  originalName: string;
};

type HeaderFile = {
  name: string;
  id: number;
  selected: boolean;
};

type TransactionWithType = {
  type: ProductTypeId;
  transactions: ImportRow[];
};

const emit = defineEmits(["cancel", "close"]);

defineProps<{
  svg?: string;
}>();

const udsColors = getUdsColors();

// Steps
const steps: {
  id: number;
  description: string;
}[] = [
  {
    id: 1,
    description: "Importer les données",
  },
  {
    id: 2,
    description: "Identifier les colonnes",
  },
  {
    id: 3,
    description: "",
  },
];

const currentStepId = ref(1);
const currentStep = computed(
  () => steps.find((step) => step.id === currentStepId.value) ?? steps[0],
);

// Files
const files = reactive<FileMappingType[]>([
  {
    id: 1,
    files: [],
    type: ProductTypeId.PRODUCT,
    name: "Marchandises",
  },
  {
    id: 2,
    files: [],
    type: ProductTypeId.SERVICE,
    name: "Prest. de services",
  },
  {
    id: 3,
    files: [],
    type: ProductTypeId.GOOD,
    name: "Produits finis",
  },
]); // to ref

const isLoading = ref(false);
const hasError = ref(false);
let headers = reactive<string[]>([]);
const currentMappingFile = ref<HeaderFile | null>(null); // to ref
let transactions = reactive<ImportRow[]>([]);

const transactionsWithType: TransactionWithType[] = [
  {
    type: ProductTypeId.PRODUCT,
    transactions: [],
  },
  {
    type: ProductTypeId.SERVICE,
    transactions: [],
  },
  {
    type: ProductTypeId.GOOD,
    transactions: [],
  },
];

const headersFile = ref<HeaderFile[]>([]);

const commercialImportHeaderList = computed(() => Object.values(commercialImportHeader));

const headersToMap = reactive<HeadersToMap[]>(
  commercialImportHeaderList.value.map((cih) => ({
    ...cih,
    position: commercialImportHeaderList.value.indexOf(cih) + 1,
    id: commercialImportHeaderList.value.indexOf(cih),
    completed: false,
    ongoing: commercialImportHeaderList.value.indexOf(cih) === 0,
    mappingHeader: null,
  })),
);

const currentHeadersToMap = computed(
  () => headersToMap.find((htm) => htm.ongoing) ?? headersToMap[0],
);

const isValid = computed(() => {
  if (currentStep.value.id === 1) {
    return files.some((fi) => fi.files.length > 0);
  } else if (currentStep.value.id === 2) {
    return isDefined(currentHeadersToMap.value?.mappingHeader) ?? false;
  }
  return true;
});

const nextButtonConfig = computed(() => {
  if (currentStep.value.id === 1) {
    return {
      name: "Importer",
      needIcon: false,
    };
  } else if (currentStep.value.id === 2) {
    return {
      name: "Suivant",
      needIcon: true,
    };
  } else {
    return {
      name: "Valider",
      needIcon: false,
    };
  }
});

function handleFile(event: { files: File[]; type: ProductTypeId }) {
  if (event.files.length > 0) {
    const tempFile = files.find((fi) => fi.type === event.type)!;
    files[tempFile.id - 1].files = event.files;
  }
}

function selectMapping(event: { mappgingFile: HeaderFile }): void {
  const { mappgingFile } = event;
  const currentHeadersToMap = headersToMap.find((htm) => htm.ongoing)!;
  const isAlreadyMapped =
    isDefined(headersToMap[currentHeadersToMap.id].mappingHeader) &&
    headersToMap[currentHeadersToMap.id].mappingHeader === mappgingFile.name;

  headersToMap[currentHeadersToMap.id].mappingHeader = isAlreadyMapped ? null : mappgingFile.name;

  currentMappingFile.value = mappgingFile;
}

function handleStepBack(): void {
  if (currentStep.value.id === 2) {
    const currentHeadersToMap = headersToMap.find((htm) => htm.ongoing);
    if (headersToMap.some((htm) => isDefined(htm.mappingHeader)) && currentHeadersToMap?.id !== 0) {
      const current = headersToMap.find((htm) => htm.ongoing)!;
      headersToMap[current.id].ongoing = false;
      headersToMap[current.id - 1].ongoing = true;
    } else {
      currentStepId.value = currentStepId.value - 1;
    }
  } else if (currentStep.value.id === 3) {
    transactions = [];
    currentStepId.value = currentStepId.value - 1;
  }
}

async function handleImportStep() {
  const allHeaders: HeadersMappingImport[] = [];
  isLoading.value = true;
  try {
    const filesToMap = files.filter((f) => f.files.length > 0);
    await Promise.all(
      filesToMap.map(async (file) => {
        const formData = new FormData();
        // @ts-expect-error
        formData.append("file", file.files[0]);
        const headersTemp = await $silex().commercialImport.getHeaders(
          // @ts-expect-error
          formData,
        );
        allHeaders.push(...headersTemp);
        headers = headersTemp;
        headersFile.value = headers.map((h) => ({
          name: h,
          id: headers.indexOf(h) + 1,
          selected: false,
        }));
      }),
    );
    const differenceInHeaders = allHeaders.filter(
      (item) => !(allHeaders.filter((x) => x === item).length > 1),
    );

    if (differenceInHeaders.length > 0 && filesToMap.length !== 1) {
      hasError.value = true;
    } else {
      hasError.value = false;
      currentStepId.value = currentStepId.value + 1;
    }
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
  }
}
async function handleMappingStep(): Promise<void> {
  const current = headersToMap.find((htm) => htm.ongoing)!;
  headersToMap[current.id].completed = true;
  const isLastMapping = headersToMap.indexOf(current) === headersToMap.length - 1;
  if (headersToMap.every((htm) => htm.completed) && isLastMapping) {
    try {
      isLoading.value = true;
      const mapping = headersToMap.reduce((acc, obj: HeadersToMap) => {
        if (obj.mappingHeader !== "Non concerné") {
          return {
            ...acc,
            [obj.originalName]: obj.mappingHeader,
          };
        } else {
          return acc;
        }
      }, {});

      const filesToMap = files.filter((f) => f.files.length > 0);

      await Promise.all(
        filesToMap.map(async (file) => {
          const transactionsTemp = await $silex().commercialImport.mapping(
            // @ts-expect-error
            file.files[0],
            {
              mappingHeaders: mapping,
            },
          );
          transactions.push(...transactionsTemp);
          transactionsWithType
            .find((trwt) => trwt.type === file.type)
            ?.transactions.push(...transactionsTemp);
        }),
      );
      currentStepId.value = currentStepId.value + 1;
    } catch (error) {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    } finally {
      isLoading.value = false;
    }
  } else {
    headersToMap[current.id].ongoing = false;
    headersToMap[current.id + 1].ongoing = true;
  }
}

async function handleTransactionStep(): Promise<void> {
  try {
    isLoading.value = true;
    const filesToMap = files.filter((f) => f.files.length > 0);
    await Promise.all(
      filesToMap.map(async (file) => {
        const transactionsTemp = transactionsWithType.find(
          (trwt) => trwt.type === file.type,
        )!.transactions;
        const formData = new FormData();
        formData.append("type", `${file.type}`);
        // @ts-expect-error
        formData.append("transactions", JSON.stringify(transactionsTemp));
        // @ts-expect-error
        formData.append("file", file.files[0]);
        if (transactionsTemp.length > 500) {
          $silex().commercialImport.createTransactions(
            // @ts-expect-error
            formData,
          );
        } else {
          await $silex().commercialImport.createTransactions(
            // @ts-expect-error
            formData,
          );
        }
      }),
    );
    emit("close");
  } catch (error) {
    $notifier().open({ type: "error", content: apiErrorToString(error) });
  } finally {
    isLoading.value = false;
  }
}

async function handleStep(direction?: string): Promise<void> {
  if (direction && direction === "back") {
    await handleStepBack();
  } else if (currentStepId.value === 1) {
    await handleImportStep();
  } else if (currentStepId.value === 2) {
    await handleMappingStep();
  } else if (currentStepId.value === 3) {
    await handleTransactionStep();
  }
}
</script>

<style lang="scss" scoped>
.step-title {
  font-weight: $uds-weight-700;
  color: $uds-neutral-900;
  font-size: 18px;
  margin-bottom: $uds-spacing-2;
  margin-top: 18px;
}

.hidden {
  display: none;
  display: hidden;
  height: 0;
  opacity: 0;
}
.upload-description {
  font-weight: $uds-weight-450;
  color: $uds-neutral-900;
  font-size: 16px;
  margin-bottom: $uds-spacing-1_5;
  line-height: 140%;
}
.upload-wrapper {
  display: flex;
  gap: $uds-spacing-2;
}

.message-error,
.message-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: $uds-radius-1;
  text-align: left;
  font-weight: $uds-weight-450;
  margin-top: $uds-spacing-1_5;
  padding: $uds-spacing-2;
  color: $uds-neutral-900;
  font-size: 14px;
  gap: $uds-spacing-2;
}
.message-error {
  border: 1px solid $uds-error-100;
  background-color: $uds-error-50;
}

.message-info {
  border: 1px solid $uds-neutral-600;
  background-color: $uds-neutral-100;
  margin-top: 10px;

  .message-content {
    display: flex;
    flex-direction: column;
  }
  .message-title {
    font-weight: $uds-weight-700;
    margin-bottom: $uds-spacing-3;
  }
}

.footer-actions {
  text-align: right;
  margin-right: $uds-spacing-1;

  .next-button {
    margin-left: $uds-spacing-1;
  }
}

:deep(.modal-inner) {
  margin: 0 $uds-spacing-2;
}
</style>

<template>
  <CommonsModalsModalBody height="auto" width="auto">
    <template #title> Importer</template>

    <template #content>
      <div class="type-import-wrapper">
        <div class="classic-import-block block">
          <img
            v-if="isComptalib()"
            class="image"
            src="@/assets/svg/comptalibClassicImport.svg"
            alt="classic_import"
          >
          <img v-else class="image" src="@/assets/svg/classicImport.svg" alt="classic_import" >
          <div class="bloc-title">Facture de Vente</div>
          <div class="bloc-text">Importez vos factures de vente, jusqu’à 10 maximum.</div>
          <uds-main-button class="button" @click="classicImport">Importer</uds-main-button>
        </div>
        <div class="mapping-import-block block">
          <img
            v-if="isComptalib()"
            class="image"
            src="@/assets/svg/comptalibMappingImport.svg"
            alt="mapping_import"
          >
          <img v-else class="image" src="@/assets/svg/mappingImport.svg" alt="mapping_import" >
          <div class="bloc-title">Données externes <span class="new-tag">New</span></div>
          <div class="bloc-text">
            Importez vos données provenant d’un autre logiciel de facturation.
          </div>
          <uds-main-button class="button" @click="mappingImport">Importer</uds-main-button>
        </div>
      </div>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import { useModal } from "vue-final-modal";
import ImportInvoice from "@/components/commons/modals/ImportInvoiceModal.vue";
import SalesMappingImport from "@/components/commons/modals/SalesMappingImportModal.vue";

const salesStore = useSalesStore();

const emit = defineEmits(["close"]);
const { isComptalib } = useBrandsComposable();

async function classicImport() {
  emit("close");
  const { open, close } = useModal({
    component: ImportInvoice,
    attrs: {
      isSales: true,
      async onClose() {
        close();
        const link = useBuildRouteFromQueryPropertiesComposable(
          defaultSalesQueryProperties.value,
          {
            ...salesStore.queryProperties,
            page: 1,
            tab: "Factures",
            itemId: null,
            checkedIds: [],
            search: null,
            descending: true,
          },
          "/sales",
        );
        await navigateTo(`${link}#forceRefresh`);
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}

async function mappingImport() {
  emit("close");
  const { open, close } = useModal({
    component: SalesMappingImport,
    attrs: {
      svg: isComptalib() ? "ComptalibBriefCaseSvg" : "MappingImportSvg",
      async onClose() {
        close();
        const link = useBuildRouteFromQueryPropertiesComposable(
          defaultSalesQueryProperties.value,
          {
            ...salesStore.queryProperties,
            page: 1,
            tab: "Factures",
            itemId: null,
            checkedIds: [],
            search: null,
            descending: true,
          },
          "/sales",
        );
        await navigateTo(`${link}#forceRefresh`);
      },
      onCancel() {
        close();
      },
    },
  });
  await open();
}
</script>

<style lang="scss" scoped>
.type-import-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .classic-import-block {
    margin-right: $uds-spacing-1;
  }

  .mapping-import-block {
    margin-left: $uds-spacing-1;

    .bloc-title {
      display: flex;
      align-items: center;
    }

    .new-tag {
      margin-left: $uds-spacing-0_5;
      padding: 0px $uds-spacing-0_5;
      width: 30px;
      font-size: 10px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $uds-white !important;
      background-color: $uds-primary-500;
      border-radius: 100px;
    }
  }
}

.image {
  width: 100px;
  margin: auto;
}

.block {
  border: 1px solid $uds-neutral-400;
  border-radius: $uds-spacing-1;
  padding: $uds-spacing-3 $uds-spacing-2 $uds-spacing-2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 252px;
  height: 300px;

  .bloc-title {
    text-align: center;
    font-weight: $uds-weight-700;
    color: $uds-neutral-900;
    font-style: normal;
    font-size: 16px;
  }
  .bloc-text {
    text-align: center;
    font-weight: $uds-weight-450;
    color: $uds-neutral-800;
    font-style: normal;
    font-size: 14px;
    margin-top: $uds-spacing-1;
    line-height: 140%;
  }

  .button {
    margin-top: $uds-spacing-2;
    width: 100%;
  }
}
</style>

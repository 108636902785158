<template>
  <div class="transactions-wrapper">
    <div class="transactions-title">
      La correspondance a bien été établie, vos données vont pouvoir être importées dans
      l’application.
    </div>
    <div class="total-transactions-block">
      <div class="transactions-icon">
        <uds-squircle-icon :key="icon.name" :icon="icon.name" :color="icon.color" :size="'small'" />
        <div class="text">Facturation importée</div>
      </div>
      <div class="vat-and-amount-cell-container">
        <div class="vaac-line">
          <uds-amount-currencie
            :value="totalTransactionsTtc"
            :has-symbol="hasSymbol(totalTransactionsTtc)"
            class="ttc-amount"
          />
          ttc
        </div>
        <div class="vaac-line --ht">
          <uds-amount-currencie
            :value="totalTransactionsHt"
            :has-symbol="hasSymbol(totalTransactionsHt)"
            class="ht-amount"
          />
          ht
        </div>
      </div>
    </div>
    <div class="transactions-list-block">
      <div class="transactions-list-count">
        {{ transactions.length }} facture{{ transactions.length > 1 ? "s" : "" }}
      </div>
      <div v-if="transactions.length > 0" class="transactions-list scrollbar">
        <div v-for="(tr, index) in transactions" :key="index" class="transaction-item">
          <div class="transactions-icon">
            <uds-icon
              icon-name="check"
              :color="udsColors.udsSuccess500"
              rounded
              reverse
              size="small"
            />
            <div class="text">{{ transactionDesignation(tr) }}</div>
          </div>
          <div class="vat-and-amount-cell-container">
            <div class="vaac-line">
              <uds-amount-currencie
                :value="tr.taxIncludedAmount"
                :has-symbol="hasSymbol(tr.taxIncludedAmount)"
                class="ttc-amount"
              />
              ttc
            </div>
            <div class="vaac-line --ht">
              <uds-amount-currencie
                :value="tr.taxExcludedAmount"
                :has-symbol="hasSymbol(tr.taxExcludedAmount)"
                class="ht-amount"
              />
              ht
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ImportRow } from "@silexpert/core";

const props = defineProps<{
  transactions: ImportRow[];
}>();

const totalTransactionsTtc = computed(() => {
  return props.transactions.reduce((acc, tr: ImportRow) => {
    return roundAtXDecimals(acc + tr.taxIncludedAmount);
  }, 0);
});

const totalTransactionsHt = computed(() => {
  return props.transactions.reduce((acc, tr: ImportRow) => {
    return roundAtXDecimals(acc + tr.taxExcludedAmount);
  }, 0);
});

const udsColors = getUdsColors();
const primaryColors = computed(() => {
  return getPrimaryColors();
});

const icon = computed(() => {
  return {
    name: "DocumentImportedInvoice",
    color: primaryColors.value.primary500,
  };
});

function transactionDesignation(tr: ImportRow): string {
  const libellé = tr.invoiceNumber ?? tr.label ?? "Facture importée";
  return `${libellé} - ${tr.customerName}`;
}
function hasSymbol(amount: number | undefined): boolean {
  return (amount ?? 0) < 0;
}
</script>

<style lang="scss" scoped>
.transactions-wrapper {
  .transactions-title {
    font-weight: $uds-weight-450;
    color: $uds-neutral-900;
    font-size: 16px;
    margin-bottom: $uds-spacing-2;
  }

  .total-transactions-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $uds-neutral-400;
    border-radius: $uds-radius-1;
    padding: $uds-spacing-2;
    margin-bottom: $uds-spacing-2;
  }

  .vat-and-amount-cell-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .total-net-cash-amount-line {
      font-style: normal;
      font-weight: $uds-weight-500;
      font-size: 18px;
      line-height: 23px;

      color: $uds-neutral-900;

      .total-net-cash-decimal-value {
        font-size: 14px;
      }
    }

    .total-ht-amount-line {
      // @extend .ds-content-book;
      color: $uds-neutral-800;

      .total-ht-decimal-value {
        font-size: 14px;
      }
    }
  }

  .transactions-icon {
    display: flex;

    .text {
      margin-left: $uds-spacing-2;
      align-self: center;
      font-weight: $uds-weight-450;
      color: $uds-neutral-900;
      font-size: 16px;
    }
  }

  .transactions-list-block {
    .transactions-list-count {
      font-weight: $uds-weight-450;
      color: $uds-neutral-800;
      font-size: 18px;
      margin-bottom: $uds-spacing-2;
    }

    .transactions-list {
      border: 0.5px solid $uds-neutral-300;
      border-radius: $uds-radius-2;
      overflow-y: scroll;

      > *:not(:last-child) {
        border-bottom: 0.5px solid #e3e3e3;
      }
      .transaction-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $uds-spacing-2;
      }
    }
  }
}
</style>
